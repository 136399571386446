import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "./Glaucoma.css";

export default function Glaucoma() {
  return (
    <div>
      <Header />
      <div className="container mt-5">
        <h1 className="text-center">Glaucoma</h1>
        <hr className="mb-3 GlaucomaLine" />
        <div>
          <div id="demo" className="carousel slide" data-ride="carousel">
            {/* The slideshow */}
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src="glaucoma.jpg"
                  className="img-fluid"
                  alt="glaucoma"
                  width={1100}
                  height={500}
                />
              </div>
              <div className="carousel-item">
                <img
                  src="glaucoma.jpg"
                  className="img-fluid"
                  alt="glaucoma"
                  width={1100}
                  height={500}
                />
              </div>
              <div className="carousel-item">
                <img
                  src="glaucoma.jpg"
                  className="img-fluid"
                  alt="glaucoma"
                  width={1100}
                  height={500}
                />
              </div>
            </div>
            {/* Left and right controls */}
            <a className="carousel-control-prev" href="#demo" data-slide="prev">
              <span className="carousel-control-prev-icon" />
            </a>
            <a className="carousel-control-next" href="#demo" data-slide="next">
              <span className="carousel-control-next-icon" />
            </a>
          </div>
          <p className="mb-4 mt-2">
            Glaucoma is an eye disease that causes loss of sight by damaging a
            part of the eye called the optic nerve. This nerve sends information
            from your eyes to your brain. When glaucoma damages your optic
            nerve, you begin to lose patches of vision, usually side vision
            (peripheral vision). Over time, glaucoma may also damage straight
            ahead (central) vision. You may not notice a loss of side vision
            until you have lost a great deal of your sight. When checking for
            glaucoma, eye doctors usually look for damage to the optic nerve and
            any loss of side vision. They may also check your eye pressure.
            Glaucoma is often called the 'silent killer' because people usually
            do not notice any signs of the disease until they have already lost
            significant vision. Once lost, vision cannot be restored.
          </p>
          <h3 className="my-4 text-center">
            What are the three major signs that a person may have glaucoma?
          </h3>
          <ul>
            <li>Optic nerve damage</li>
            <li>Vision loss (visual field loss)</li>
            <li>Increased eye pressure (elevated intraocular pressure).</li>
          </ul>
          <h3 className="my-4 text-center">Who is at Risk for Glaucoma?</h3>
          <ul>
            <li>Age: The older you are, the greater your risk</li>
            <li className="my-3">
              Family history: If you have a parent, brother or sister with
              glaucoma, you are more likely to get glaucoma too. If you have
              glaucoma, your family members should get complete eye exams.
            </li>
            <li className="my-3">
              Medical history: Diabetes, previous eye injuries, eye surgery or
              long-term steroid use can increase your risk of glaucoma. Anyone
              can get glaucoma it affects one in 200 people until the age of 50.
              The rate increases to one in 10 over the age of 80.
            </li>
          </ul>
          <h3 className="my-4 text-center">
            There are many types of glaucoma. The most common types include:
          </h3>
          <ul>
            <li className="my-3">
              Chronic (Open Angle) Glaucoma: This is the most common type. In
              open angle glaucoma, aqueous fluid drains too slowly and pressure
              inside the eye builds up. It usually results from aging of the
              drainage channel, which doesn't work as well over time. However,
              younger people can also get this type of glaucoma.
            </li>
            <li className="my-3">
              Normal Tension Glaucoma: This is a form of open angle glaucoma not
              related to high pressure. People with normal tension glaucoma may
              be unusually sensitive to normal levels of pressure. Reduced blood
              supply to the optic nerve may also play a role in normal tension
              glaucoma.
            </li>
            <li className="my-3">
              Acute (Angle Closure) Glaucoma: Less than 10 percent of have this
              form, but It causes a sudden rise in pressure, requiring
              immediate, emergency medical care. The signs are usually serious
              and may include blurred vision, severe headaches, eye pain,
              nausea, vomiting or seeing rainbow-like halos around lights.
              Occasionally, the condition may be without symptoms; similar to
              open angle.
            </li>
            <li className="my-3">
              Secondary Glaucoma: Another 10 percent of glaucoma cases come from
              certain diseases and conditions that damage the eye's drainage
              system. These include diabetes, leukemia, and sickle-cell anemia
              also some forms of arthritis, cataracts, eye injuries or
              inflammation of the eye plus steroid drug use and growth of
              unhealthy blood vessels.
            </li>
            <li className="my-3">
              Post-Surgical Glaucoma: which happen due to some surgeries, such
              as retinal reattachments increase the chance of getting glaucoma.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
}
