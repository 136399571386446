import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "../Header/Header.css";
import { Link } from "react-router-dom";

export default function Header(props) {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <div>
      <nav class="navbar navbar-expand-sm d-none d-sm-flex justify-content-center">
        <ul class="navbar-nav">
          <li class="nav-item mx-3 uppernav">
            <i className="fa fa-phone fa-lg"></i>{" "}
            <span>+91 9122550310 /+91 9708445776</span>
          </li>{" "}
          <br />
          <li class="nav-item mx-3 uppernav">
            <i className="fa fa-map-marker fa-lg"></i>{" "}
            <span> Near Manav Hospital Bhootnath Road, Patna, Bihar</span>
          </li>{" "}
          <br />
          <li class="nav-item mx-3 uppernav">
            <i className="fa fa-clock-o fa-lg"></i>{" "}
            <span>10:00am - 7.00pm</span>
          </li>{" "}
          <br />
        </ul>
      </nav>
      {/* navbar section */}

      <nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top">
        <Link class="navbar-brand" to="/">
          <img src="logo.png" alt="logo" width="100%" height="100%" />
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContentLG"
          aria-controls="navbarSupportedContentLG"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContentLG">
          <ul class="navbar-nav m-auto">
            <li class="nav-item active">
              <Link to="/" class="nav-link">
                Home <span class="sr-only">(current)</span>
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/ourdoctors" class="nav-link">
                Our Doctors
              </Link>
            </li>
            <li class="nav-item">
              {" "}
              <Link to="/about" class="nav-link">
                About Us
              </Link>{" "}
            </li>
            <li class="nav-item dropdown">
              <Link
                class="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdownLG"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Services
              </Link>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownLG">
                <Link to="/cataract" class="dropdown-item">
                  Cataract
                </Link>
                <Link to="/cornea" class="dropdown-item">
                  Cornea Transplant
                </Link>
                <Link to="/glaucoma" class="dropdown-item">
                  Glaucoma
                </Link>

                <Link class="dropdown-item" to="/lasik">
                  Lasik
                </Link>
                <Link class="dropdown-item" to="/lowvisiontreatment">
                  Low Vision Treatment
                </Link>
                <Link to="/retinavitreousservice" class="dropdown-item">
                  Retina Vitreous Service
                </Link>
                <Link to="/contactlensservices" class="dropdown-item">
                  Contact Lens Service
                </Link>
                <Link to="/squintservices" class="dropdown-item">
                  Squint Service
                </Link>
              </div>
            </li>
            <li class="nav-item">
              <Link to="/contact" class="nav-link">
                Contact Us
              </Link>
            </li>
            <li className="mr-auto">
              <button
                onClick={onOpenModal}
                class="btn btn-outline-info"
                type="button"
                data-toggle="modal"
                data-target="#myModal"
              >
                Appointment
              </button>
              <Modal open={open} onClose={onCloseModal} center>
                <div className="container">
                  <br />
                  <form className="form-group">
                    <h2 class="AppointmentForm">
                      Please fill it to make an appointment
                    </h2>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                        autofocus
                        autoComplete="on"
                      />
                      <span className="help-block" />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="email_addr"
                        placeholder="Email address"
                        required
                      />
                      <span className="help-block" />
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control"
                        id="phone_number"
                        placeholder="Phone number"
                      />
                      <span className="help-block" />
                    </div>
                    <div className="form-group">
                      <input
                        type="date"
                        className="form-control"
                        id="dob"
                        placeholder="Dob"
                      />
                      <span className="help-block" />
                    </div>
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        id="age"
                        placeholder="Age"
                        min={1}
                        max={110}
                        required
                      />
                      <span className="help-block" />
                    </div>
                    <button
                      className="btn btn-lg btn-primary btn-block"
                      type="submit"
                    >
                      Make appointment
                    </button>
                  </form>
                </div>
              </Modal>
            </li>
          </ul>
        </div>
      </nav>

      {/* navbar section end */}

      {/* <div>
        <img
          src="https://www.chandigarheyecare.com/images/s1.webp"
          className="img-fluid"
        />
      </div> */}

      {props.show ? (
        <div id="demo" className="carousel slide" data-ride="carousel">
          {/* Indicators */}
          <ul className="carousel-indicators">
            <li data-target="#demo" data-slide-to={0} className="active" />
            <li data-target="#demo" data-slide-to={1} />
            <li data-target="#demo" data-slide-to={2} />
          </ul>
          {/* The slideshow */}
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/images/img6.jpeg"
                className="imgCarousel"
                alt="Chandigarh Eye Care"
              />
            </div>
            <div className="carousel-item">
              <img
                src="/images/img7.jpeg "
                className="imgCarousel"
                alt="Chandigarh Eye Care"
              />
            </div>
            <div className="carousel-item">
              <img
                src="/images/img8.jpeg"
                className="imgCarousel"
                alt="Chandigarh Eye Care"
              />
            </div>
          </div>
          {/* Left and right controls */}
          <a
            className="carousel-control-prev"
            href="#demo"
            aria-label="Explore more about Chandigarh Eye Care Clinic"
            rel="noopener noreferrer"
            data-slide="prev"
          >
            <span className="carousel-control-prev-icon" />
          </a>
          <a
            className="carousel-control-next"
            href="#demo"
            aria-label="Explore more about Chandigarh Eye Care Clinic"
            rel="noopener noreferrer"
            data-slide="next"
          >
            <span className="carousel-control-next-icon" />
          </a>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
