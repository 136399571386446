import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "./SquintService.css";

export default function SquintService() {
  return (
    <div>
      <Header />
      <div className="container mt-5">
        <h1 className="text-center">Squint Service</h1>
        <hr className="mb-5 SquintLine" />
        <div>
          <div id="demo" className="carousel slide" data-ride="carousel">
            {/* The slideshow */}
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src="squint.jpg"
                  className="img-fluid"
                  alt="squint"
                  width={1100}
                  height={500}
                />
              </div>
              <div className="carousel-item">
                <img
                  src="squint.jpg"
                  className="img-fluid"
                  alt="squint"
                  width={1100}
                  height={500}
                />
              </div>
              <div className="carousel-item">
                <img
                  src="squint.jpg"
                  className="img-fluid"
                  alt="squint"
                  width={1100}
                  height={500}
                />
              </div>
            </div>
            {/* Left and right controls */}
            <a className="carousel-control-prev" href="#demo" data-slide="prev">
              <span className="carousel-control-prev-icon" />
            </a>
            <a className="carousel-control-next" href="#demo" data-slide="next">
              <span className="carousel-control-next-icon" />
            </a>
          </div>
        </div>
        <p className="my-4">
          The medical name for squint is strabismus .It is a condition where the
          eyes do not look together in the same direction. Whilst one eye looks
          straight ahead, the other eye turns to point inwards, outwards,
          upwards or downwards. Squints are common and affect about 1 in 20
          children. You might even spot that your baby has a squint. Most
          squints develop before preschool age, usually by the time a child is
          three years old. Sometime A child with a squint may stop using the
          affected eye to see with. This can lead to visual loss called
          amblyopia, which can become permanent unless treated early in
          childhood. squints develop in older children, or in adults.
        </p>
        <h3>What Causes Squint?</h3>
        <p className="my-4">
          A squint develops when the eye muscles do not work in a balanced way
          and the eyes do not move together correctly.
        </p>
        <p className="my-4">
          Yes, there are different types of squint. Squints can be divided into
          different categories:
        </p>
        <ul>
          <li>
            By the direction of the squinting (turning) eye: in, out , up or
            down?
          </li>
          <li>Constant or Intermittent?</li>
          <li>Manifest or latent?</li>
          <li>
            Concomitant or incomitant i.e., whether the severity (angle) of the
            squint is the same in all directions or not?
          </li>
        </ul>
        <h3 className="my-4">
          What are the types and causes of squint in children?
        </h3>
        <p className="my-4">
          About 5 in 100 children aged five years old have a squint. It is quite
          common to notice a brief squint when tired or daydreaming. Babies
          sometimes cross their eyes - it is quite normal for this to happen
          occasionally, especially when they are tired. Some squints are much
          more obvious than others. You might notice your child has an eye that
          does not look straight ahead. Another sign of squint is that your
          child might close one eye when looking at you, or turns his or her
          head on one side.
        </p>
        <ul>
          <li>Congenital squints of unknown cause</li>
          <li>Squint related to refractive errors</li>
          <li>Other Cause.</li>
        </ul>
        <p className="my-4">
          In some cases squint might be a manifestation of an underlying eye
          problem like In some cases, a squint is one feature of a more
          generalised genetic or brain condition. Squints can occur in some
          children with cerebral palsy, Noonan's or Down's syndrome,
          hydrocephalus, brain injury or tumour, retinoblastoma (a rare type of
          eye cancer) and several other conditions.
        </p>
        <h3>Amblyopia</h3>
        <p className="my-4">
          Amblyopia is sometimes called a lazy eye. It is a condition where the
          vision in an eye is poor and it is caused by lack of use of the eye in
          early childhood. If amblyopia is not treated before the age of about 7
          years, the visual impairment usually remains permanent. A squint is
          the most common cause of amblyopia. In many cases of squint, one eye
          remains the dominant focusing eye (the one that sees). The other,
          turned eye (the squinting one) is not used to focusing, and the brain
          ignores the signals from this eye. The turned eye then fails to
          develop the normal visual pathways in childhood and amblyopia develops
          in this eye.
        </p>
        <h3>How the squint looks</h3>
        <p className="my-4">
          A squint can be a cosmetic problem. Many older children and adults who
          did not have their squint treated as a child have a reduced
          self-esteem because of the way their squint looks to other people.
          Impaired binocular vision
        </p>
        <p className="my-4">
          This means the child does not have a good sense of depth when looking
          at objects. As a result, he or she cannot see properly in three
          dimensions.
        </p>
        <h3>How is a squint diagnosed and assessed?</h3>
        <p className="my-4">
          It is important to diagnose a squint (and amblyopia) as early as
          possible. Routine checks to detect eye problems in babies and children
          are usually done at the newborn examination and at the 6- to 8-week
          review. There is also a routine preschool or school-entry vision
          check.
        </p>
        <p className="my-4">
          Some newborn babies have a mild intermittent squint that soon goes.
          However, fixed squints are usually permanent unless treated. So, as a
          guide.
        </p>
        <p className="my-4">
          Any squint seen in a newborn baby should always be intermittent (come
          and go), reducing by 2 months of age, and gone by 4 months of age. A
          baby with a constant fixed squint, or with an intermittent squint that
          is worsening from 2 months, should be referred for assessment.
        </p>
        <h3>What are the treatments for squint?</h3>
        <p className="my-4">Treatment typically involves the following:</p>
        <ul>
          <li className="my-2">
            Occlusion: Treating amblyopia (visual loss) if this is present by
            patching.
          </li>
          <li className="my-2">
            Optics: Wearing glasses to correct any refractive error, if this is
            present.
          </li>
          <li className="my-2">Orthoptic Exercises</li>
          <li className="my-2">
            Operation: Surgery is often needed to correct the appearance of the
            squint itself, and may help to restore binocular vision in some
            cases
          </li>
        </ul>
        <h3 className="my-4">Correcting refractive errors</h3>
        <p>
          If a child has a refractive error (long or short sight, for example)
          then glasses will be prescribed. This corrects vision in the eye. It
          may also straighten the squinting eye, if the refractive error was the
          cause of the squint.
        </p>
        <h3 className="my-4">Surgery</h3>
        <p>
          In many cases an operation is advised to make the eyes as straight as
          possible. The main aim of surgery is to improve the appearance of the
          eyes. In some cases, surgery may also improve or restore binocular
          vision (this means that the two eyes are working together).
        </p>
        <h3 className="my-4">Botulinum toxin</h3>
        <p className="my-4">
          Botulinum toxin (also know as Botox®) stops muscle cells from working
          (it paralyses them). It is used for a variety of conditions where it
          is helpful to weaken one or more muscles. In recent years, injections
          of botulinum toxin directly into eye muscles have been used as a
          treatment for certain types of squint.
        </p>
      </div>
      <Footer />
    </div>
  );
}
