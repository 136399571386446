import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "./Lasik.css";

export default function Lasik() {
  return (
    <div>
      <Header />
      <div className="container mt-5">
        <h1 className="text-center">Lasik eye Surgery</h1>
        <hr className="mb-5 LasikLine" />
        <div>
          <div id="demo" className="carousel slide" data-ride="carousel">
            {/* The slideshow */}
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src="lasik.jpeg"
                  className="img-fluid"
                  alt="lasik"
                  width={1100}
                  height={500}
                />
              </div>
              <div className="carousel-item">
                <img
                  src="lasik.jpeg"
                  className="img-fluid"
                  alt="lasik"
                  width={1100}
                  height={500}
                />
              </div>
              <div className="carousel-item">
                <img
                  src="lasik.jpeg"
                  className="img-fluid"
                  alt="lasik"
                  width={1100}
                  height={500}
                />
              </div>
            </div>
            {/* Left and right controls */}
            <a className="carousel-control-prev" href="#demo" data-slide="prev">
              <span className="carousel-control-prev-icon" />
            </a>
            <a className="carousel-control-next" href="#demo" data-slide="next">
              <span className="carousel-control-next-icon" />
            </a>
          </div>
          <p className="my-4">
            LASIK (LASER IN SITU KERATOMILEUSIS) is an advanced laser surgery
            that permanently eliminates your dependence on corrective eyewear
            i.e. glasses and / or contact lenses with the resultant lifestyle
            advantages. It is used to correct refractive errors; LASIK eye
            surgery is a treatment that reshapes the cornea in order to produce
            clear vision. The LASIK refractive eye surgery procedure can treat
            myopia (near sightedness), hyperopia (farsightedness), and
            astigmatism, and reduce patients' dependency on contact lenses and
            glasses. LASIK eye surgery provides individuals with an effective
            treatment option, where a predetermined amount of corneal tissue is
            removed. This reshaping of the cornea improves the eye's focusing
            power and enhances patients' visual acuity. LASIK improves the
            uncorrected vision for distance - one's visual capacity while not
            wearing corrective lenses - in most patients who have the procedure.
            Nearly all patients can expect to achieve 20/40 vision, which most
            states consider good enough to drive without having to wear contacts
            or glasses, and many can expect to achieve 20/20 vision or better.
            However, there are no guarantees that you will have perfect vision.
          </p>
          <h3>ARE YOU AN IDEAL CANDIDATE FOR CORNEAL REFRACTIVE SURGERY?</h3>
          <p className="my-4">
            If you are considering corneal refractive surgery, you must:
          </p>
          <ul>
            <li className="my-3">
              Be at least 18 years of age, with a stable refraction for the past
              6 months.
            </li>
            <li className="my-3">
              Have healthy eyes that are free from any eye diseases or corneal
              abnormalities (eg Scar, Infection, etc.).
            </li>
            <li className="my-3">
              RGP/Semi soft lens use to be discontinued 2 weeks prior to LASIK
            </li>
            <li className="my-3">
              Soft contact lens use to be discontinued 1 week before LASIK.
            </li>
            <li className="my-3">
              Be informed of corneal Refractive Surgery risks and benefits as
              compared to existing treatments for refractive errors.
            </li>
            <li className="my-3">Be able to lie flat without difficulty.</li>
            <li className="my-3">
              Be able to tolerate local/topical anaesthesia.
            </li>
            <li className="my-3">
              Be willing to sign an informed consent form prior to the
              procedure.
            </li>
            <li className="my-3">
              Be able to keep your eyes accurately on the fixation light during
              the laser treatment.
            </li>
          </ul>
          <h3>FAQs ON REFRACTIVE SURGEY (LASIK)</h3>
          <h5>1. Will my vision improve immediately?</h5>
          <p className="my-4">
            You should be able to see reasonably well within a few days after
            your procedure. If you have had a SURFACE PRK procedure, your vision
            may fluctuate for the next 1 to 3 months and reading fine print may
            be difficult. If you have had a LASIK procedure, your vision is
            likely to stabilize around a month after the procedure.
          </p>
          <h5>2. What are the possible side effects of the procedure?</h5>
          <p className="my-4">
            Early side effects of any corneal procedure include light
            sensitivity, glare and foreign body sensation in the eye. Both
            SURFACE PRK and LASIK can sometimes result in under correction or
            incomplete removal of the refractive error. Occasionally, an
            overcorrect ion eg., the correction of more myopia than intended,
            can also occur. A change in the clarity of the corneal tissues –
            termed corneal haze – can also occur in some patients.
          </p>
          <h5>3. How do I know if I am a good candidate for LASIK?</h5>
          <p className="my-4">
            A large percentage of nearsighted, farsighted, and astigmatic
            patients are potential candidates for LASIK. Patients who are 18
            years of age or older,have healthy eyes that are free from retinal
            problems, corneal scars, and any eye disease are suitable. Along
            with being medically suitable, the best candidates are people with a
            lifestyle or occupation in which they are dissatisfied with their
            contact lenses or glasses. By having an evaluation, examination, and
            consultation with you, the doctor will be able to determine if you
            are a good candidate.
          </p>
          <h5>4. Is the effect of LASIK permanent?</h5>
          <p className="my-4">
            Yes. Following an initial healing period of two to three months, the
            effect of the treatment is lifelong.
          </p>
          <h5>5. Will I need reading glasses after the treatment?</h5>
          <p className="my-4">
            Generally, patients under 40 years of age read well without glasses
            following the treatment. Patients over 40 may need magnification for
            reading fine print. Presbyopia is the term that refers to the
            natural weakening of the focusing muscles that occurs in our early
            to mid-40s, causing us to need the magnification that reading
            glasses provide. The LASIK treatment does not correct or prevent
            presbyopia.
          </p>
          <h5>
            6. How many times do I need to be seen following the treatment?
          </h5>
          <p className="my-4">
            Most patients are usually seen the day following the treatment and
            then at least 1 month and 3 months following the treatment. Some
            patients require more visits. Remember, we are available 24 hours a
            day when you have any questions, concern or need attention.
          </p>
          <h5>
            7. How soon after the treatment can I bathe and take a shower?
          </h5>
          <p className="my-4">
            Your physician will advise you as to the specifics, however,
            typically you can bathe the same day of the treatment but you should
            not shower for 2 days after the treatment. As always, avoid getting
            soap or water directly into your eyes. Avoid rubbing your eyes
            during the first month after surgery.
          </p>
          <h5>8. When can I drive after the treatment?</h5>
          <p className="my-4">
            You should not drive on the day of the treatment. After that, you
            may drive when you feel confident that your visual acuity and eye
            comfort allow you to drive safely.
          </p>
          <h5>9. How soon can I use eye makeup?</h5>
          <p className="my-4">
            You may resume wearing eye makeup 2 weeks after your treatment. We
            strongly recommend that you purchase new eye makeup, specifically
            mascara, to avoid potential infection following your treatment.
          </p>
          <h5>10. How soon after the treatment can I exercise?</h5>
          <p className="my-4">
            Non-contact sports can be resumed as soon as you feel capable. Eye
            protection is always recommended for racquet sports or rough sports
            where there is a risk of being hit in the eye.
          </p>
          <h5>11. How soon can I swim?</h5>
          <p className="my-4">
            You should stay out of the swimming pools for two weeks after your
            treatment, and rivers, lakes and oceans for 3-4 weeks following your
            treatment.
          </p>
          <h5>12. What kind of Anesthesia is used during the treatment?</h5>
          <p className="my-4">
            Numbing eye drops are used. Sometimes an oral medication is used to
            help with relaxation. No needle or intravenous drugs are used.
          </p>
          <h5>13. Can both the eyes be treated at the same time?</h5>
          <p className="my-4">
            In LASIK– Both eyes are performed same time, but SURFACE PRK and Epi
            LASIK is performed one eye at a time. The timing of treatment of the
            second eye is best decided in consultation with your
            ophthalmologist.
          </p>
          <h5>14. Does LASIK hurt?</h5>
          <p className="my-4">
            The treatment itself is painless because we put a few numbing drops
            in your eyes to make you even more comfortable. You will feel
            pressure on and around the eye during treatment. With LASIK, some
            patients may experience a feeling of "something in the eye" for a
            day or so after treatment.
          </p>
          <h5>15. Can I see the treatment as it is being performed?</h5>
          <p className="my-4">
            You will be "awake" but very relaxed. You will not be able to see
            any of the details of the treatment. You will see a number of
            lights, but the images will be blurred.
          </p>
          <h5>16. Will I experience pain after the treatment?</h5>
          <p className="my-4">
            Some patients experience a feeling of burning or scratchiness. This
            feeling generally lasts from one to three hours following the
            treatment. Most people feel fine if they take a nap soon after the
            treatment.
          </p>
          <h5>17. Will my eyes be patched?</h5>
          <p className="my-4">
            No, but clear, protective eye shields will be placed over your
            treated eye(s) following the treatment, and will be removed in our
            office the next morning. You will continue to wear these eye shields
            at bedtime for 1 week for protection.
          </p>
          <h5>
            18. Will I need to wear spectacles or contact lenses after the
            procedure?
          </h5>
          <p className="my-4">
            Both SURFACE PRK and LASIK are designed to allow you to discontinue
            the use of corrective lenses. In some patients with high refractive
            errors and in those over 40 years of age, corrective lenses may
            still be required. In some patients however, it may be possible to
            perform a second refractive procedure to correct a residual
            refractive error.
          </p>
          <h5>19. Is the LASIK procedure covered by insurance?</h5>
          <p className="my-4">
            LASIK (Corneal Refractive Surgery) is considered as elective/
            cosmetic surgery and it is not covered by many health insurance
            plans. However you can contact our insurance section staff after
            your LASIK consultation. You may also contact your insurance agency
            for the same.
          </p>
          <h5>20. When can I resume my normal activities?</h5>
          <p className="my-4">
            If you have had a SURFACE PRK, you will be able to return to work
            within three to four days of surgery. If you have had LASIK
            procedures, you can return to work on the following day. Eye
            medications should be continued during working hours as instructed
            by the surgeon. While most activities can be resumed after this
            time, the face should not be placed under water for at least one
            week following LASIK. Care should also be taken to avoid any trauma
            to the eye including rubbing of the eyes during this period.
          </p>
          <h5>21. What are the criteria for lasik surgery?</h5>
          <ul>
            <li className="my-3">
              Age of the patient should be over 18 years.
            </li>
            <li className="my-3">Corneal thickness.</li>
            <li className="my-3">A Complete retinal evaluation.</li>
            <li className="my-3">
              RGP/Semi soft lens use to be discontinued 3 weeks prior to LASIK.
            </li>
            <li className="my-3">
              Soft contact lens use to be discontinued 1 week before LASIK.
            </li>
          </ul>
          <h5>22. Is Lasik a safe procedure?</h5>
          <ul>
            <li className="my-3">
              Yes if the requisite tests and above criteria are followed.
            </li>
            <li className="my-3">
              Corneal thickness more than 480 microns which will be evaluated by
              us.
            </li>
            <li className="my-3">If done after a thorough screening.</li>
            <li className="my-3">A good centre / clinic are booked.</li>
            <li className="my-3">Use of latest equipment is used.</li>
            <li className="my-3">Done under the care of expert hands.</li>
            <li className="my-3">It is an FDA approved procedure</li>
          </ul>
          <h5>23. Up to what number can LASIK correct?</h5>
          <p className="my-4">
            A wide range of refractive errors can be corrected including near
            sightedness, far sightedness & astigmatism. Near sightedness is an
            eye number which ranges from 0 to minus 22 diopter. Far sightedness
            is a number which ranges from 0 to plus 5 diopter. Astigmation
            (Cylindrical) 0 to 6 diopter cyl.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
