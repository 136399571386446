import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "./RetinaVitreous.css";

export default function RetinaVitreousService() {
  return (
    <div>
      <Header />
      <div className="container mt-5">
        <h1 className="text-center">Retina Vitreous Service</h1>
        <hr className="mb-5 RetinaLine" />
        <div>
          <div id="demo" className="carousel slide" data-ride="carousel">
            {/* The slideshow */}
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src="retinaservice.png"
                  className="img-fluid"
                  alt="retina"
                  width={1100}
                  height={500}
                />
              </div>
              <div className="carousel-item">
                <img
                  src="retinaservice.png"
                  className="img-fluid"
                  alt="retina"
                  width={1100}
                  height={500}
                />
              </div>
              <div className="carousel-item">
                <img
                  src="retinaservice.png"
                  className="img-fluid"
                  alt="retina"
                  width={1100}
                  height={500}
                />
              </div>
            </div>
            {/* Left and right controls */}
            <a className="carousel-control-prev" href="#demo" data-slide="prev">
              <span className="carousel-control-prev-icon" />
            </a>
            <a className="carousel-control-next" href="#demo" data-slide="next">
              <span className="carousel-control-next-icon" />
            </a>
          </div>
        </div>
        <p className="my-4">
          The Retina is the light sensitive film in the back of the eye. The
          image is perceived here and transmitted to the brain by the optic
          nerve. The vitreous is the clear gel that fills the back of the eye.
          Diseases of the retina can affect any age.
        </p>
        <p className="my-4">
          Premature infants can be affected by a disease called - ROP
          (Retinopathy of pre-maturity). Heredity and age related degenerations
          can affect the retina - especially the central most sensitive part of
          the retina called 'macula'.
        </p>
        <p className="my-4">
          The retina can detach from the back of the eye - a condition called
          'Retinal Detachment'. The Vitreous gel can become opaque due to blood
          - a condition called 'Vitreous hemorrhage' this condition can occur in
          diabetes, following injury and in other conditions too.
        </p>
        <p className="my-4">
          FFA (FUNDUS Fluorescein Angiography) Fluorescein angiography, is a
          clinical test to look at blood circulation inside the eye, it aids in
          the diagnosis of retinal conditions associated with diabetes,
          age-related macular degeneration, and other eye abnormalities.
        </p>
        <p className="my-4">
          Fluorescein, an orange-red dye, is injected into a vein in the arm.
          The dye travels through the body to the blood vessels in the retina. A
          special camera with a filter flashes a blue light into the eye and
          takes multiple photographs of the retina and also helps to monitor the
          course of the disease and its treatment. It may be repeated on
          multiple occasions. No X-rays are involved.
        </p>
        <p className="my-4">
          If there are abnormal blood vessels, the dye leaks into the retina or
          stains the blood vessels. Damage to the lining of the retina or a
          typical new blood vessels may be revealed as well. These abnormalities
          are determined through a careful interpretation of the photographs by
          an ophthalmologist.
        </p>
      </div>
      <Footer />
    </div>
  );
}
