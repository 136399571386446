import React from "react";
import "../Footer/Footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
      <footer className="bg-success">
        <div class="container">
          <div class="row">
            <div class="col-md-6 footer-column">
              <Link class="navbar-brand" to="/">
                <img
                  src="logo.png"
                  alt="logo"
                  className="my-4"
                  style={{ width: "100%", height: "100%" }}
                />
              </Link>
              <p className="text-warning">
                Chandigarh Eye Care, Patna, in operation from 2015, has
                maintained its prominence in the area of Ophthalmology as one of
                the best Eye Care Centre in Patna with the latest technologies
                and innovation in services. We have best eye doctor in patna
                with experience of handling thousands of patients till now.
              </p>
            </div>

            <div class="col-md-6 footer-column">
              <h3 className="text-light mb-5 mt-4">Address</h3>
              <p className="text-warning">
                <i class="fa fa-map-marker text-light mr-2"></i>Near Manav
                Hospital Bhootnath Road, Patna, Bihar
              </p>
              <p className="text-warning">
                <i class="fa fa-phone text-light mr-2"></i> +91 9122550310 / +91
                9708445776
              </p>
              <p className="text-warning">
                <i class="fa fa-envelope text-light mr-2"></i>{" "}
                chandigardheyecare@gmail.com
              </p>
            </div>
          </div>

          <div class="text-center">
            <i class="fa fa-ellipsis-h"></i>
          </div>

          <div class="row text-center">
            <div class="col-md-4 box">
              <span class="copyright quick-links">
                Copyright ©2021 All rights reserved by{" "}
                <a
                  href="https://skillninjas.in/"
                  rel="noopener"
                  target="_blank"
                >
                  SkillNinjas
                </a>
              </span>
            </div>
            <div class="col-md-4 box">
              <ul class="list-inline social-buttons">
                <li class="list-inline-item">
                  <a href="#">
                    <i class="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <i class="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-4 box">
              <ul class="list-inline quick-links">
                <li class="list-inline-item">
                  <a href="#">Privacy Policy</a>
                </li>
                <li class="list-inline-item">
                  <a href="#">Terms of Use</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
