import React, { useState } from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import "./Contact.css";

export default function Contact() {
  return (
    <div>
      <Header />
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            className="gmap_iframe"
            width="100%"
            frameBorder={0}
            scrolling="no"
            marginHeight={0}
            marginWidth={0}
            src="https://maps.google.com/maps?width=600&height=400&hl=en&q=chandigarh eye care&t=k&z=16&ie=UTF8&iwloc=B&output=embed"
          />
          <a href="https://www.fridaynightfunkin.net/friday-night-funkin-mods-fnf-play-online/">
            FNF Mods
          </a>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".mapouter{position:relative;text-align:right;width:100%;height:400px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:400px;}.gmap_iframe {height:400px!important;}",
          }}
        />
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-sm-6">
            <h3 className="text-center mt-5">Contact Info</h3>
            <div className="p-5">
              <h5 className="my-3">Address:</h5>
              <i className="fa fa-map-marker mr-3"></i>
              Near Manav Hospital Bhootnath Road, Patna, Bihar
              <h5 className="my-3">Phone:</h5>
              <i className="fa fa-phone mr-3"></i>+91 9122550310 / +91
              9708445776
              <h5 className="my-3">Email:</h5>
              <i className="fa fa-envelope mr-3"></i>
              chandigardheyecare@gmail.com
            </div>
          </div>
          <div className="col-sm-6">
            <h3 className="ml-5 my-3">Get In Touch With Us</h3>
            <div className="w-75 ml-5 mb-5">
              <form action="/action_page.php">
                <div className="form-group">
                  <label htmlFor="name">Your Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your name"
                    id="name"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    id="email"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="number">Mobile Number:</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter number"
                    id="number"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <label>Your Message</label>
                  <textarea className="form-control" defaultValue={""} />
                </div>
                <button type="submit" className="btn btn-primary">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
