import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "./Cornea.css";

export default function Cornea() {
  return (
    <div>
      <Header />
      <div className="container mt-5">
        <h1 className="text-center">Cornea Transplant Service</h1>
        <hr className="mb-5 corneaLine" />
        <div>
          <div id="demo" className="carousel slide" data-ride="carousel">
            {/* The slideshow */}
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src="cornea.jpg"
                  className="img-fluid"
                  alt="cornea"
                  width={1100}
                  height={500}
                />
              </div>
              <div className="carousel-item">
                <img
                  src="cornea.jpg"
                  className="img-fluid"
                  alt="cornea"
                  width={1100}
                  height={500}
                />
              </div>
              <div className="carousel-item">
                <img
                  src="cornea.jpg"
                  className="img-fluid"
                  alt="cornea"
                  width={1100}
                  height={500}
                />
              </div>
            </div>
            {/* Left and right controls */}
            <a className="carousel-control-prev" href="#demo" data-slide="prev">
              <span className="carousel-control-prev-icon" />
            </a>
            <a className="carousel-control-next" href="#demo" data-slide="next">
              <span className="carousel-control-next-icon" />
            </a>
          </div>
          <p className="my-4">
            The cornea is the clear front dome shaped window of the eye. It
            provides about 2/3 of the eye's focusing power. It gives us a clear
            window to look through and is the key to good vision. It also helps
            shield the rest of the eye from germs, dust, and other harmful
            pollutants.The Corneal Service at our centre delivers medical and
            surgical care to a wide variety of corneal, external eye related
            diseases and anterior segment eye disorders.The Service is managed
            by a trained corneal sub-specialist ophthalmologist. Some of the
            problems dealt with are:
          </p>
          <h3 className="text-center">INFECTIONS</h3>
          <hr className="mb-5 corneaLine" />
          <p className="my-4">
            Rapid and appropriate management of patients with infections is of
            paramount importance to minimize visual loss, relieve pain,
            eliminate the infectious agent, and minimize structural damage to
            the cornea and the other ocular structures. The common conditions
            are:
          </p>
          <ul>
            <li>Conjunctivitis (red eye)</li>
            <li>Corneal ulcersy</li>
            <li>Scleral ulcers</li>
          </ul>
          <h3 className="text-center">CORNEAL DYSTROPHIES</h3>
          <hr className="mb-5 corneaLine" />
          <p className="my-4">
            Corneal dystrophy is a condition in which one or more parts of the
            cornea lose their normal clarity. There are over 20 corneal
            dystrophies that affect all parts of the cornea. Some cause severe
            visual impairment while a few cause no vision problem and are
            discovered during a routine eye examination. Other dystrophies may
            cause repeated episodes of pain without leading to permanent loss of
            vision. Some of the most common corneal dystrophies include:
          </p>
          <ul>
            <li>Fuchs' dystrophy)</li>
            <li>Lattice dystrophy</li>
            <li>Map-dot-fingerprint dystrophy</li>
          </ul>
          <h3 className="text-center">CORNEAL ECTATIC DISORDERS</h3>
          <hr className="mb-5 corneaLine" />
          <p className="my-4">
            These disorders are characterized by a progressive change in the
            shape of the cornea that results in a decrease in vision. Contact
            lenses are of benefit and obviate the need for surgery in most
            cases. However, a small portion of patients reach the point where
            they need a cornea transplant. Some of the common diseases include:
          </p>
          <ul>
            <li>Keratoconus</li>
            <li>Pellucid marginal degeneration</li>
            <li>Terriens marginal degeneration</li>
          </ul>
          <h3 className="text-center">CORNEAL INJURY</h3>
          <hr className="mb-5 corneaLine" />
          <ul>
            <li className="my-3">
              Injuries may result from severe blunt trauma or any kind of
              penetrating injury. The aim is to restore and maintain the
              integrity of the globe, avoid further intraocular damage and
              prevent permanent corneal scarring and astigmatism.
            </li>
            <li className="my-3">
              Surgical facilities offered by the cornea service
            </li>
            <li className="my-3">
              Transplantation which involves replacing a diseased or scarred
              cornea with a new one. In corneal transplant surgery the surgeon
              removes the central portion of the cloudy cornea and replaces it
              with a clear cornea. The chances of success of this operation have
              risen dramatically because of technological advances. Corneal
              transplantation has restored sight to many, who a generation ago
              would have been blinded permanently by corneal injury, infection,
              or inherited corneal diseases or degeneration. Recent modified
              forms of corneal transplant called Lamellar Keratoplasty are also
              performed. This involves removing only the partial thickness of
              the cornea that is diseased leaving the rest of the healthy cornea
              undisturbed. Lamellar Keratoplasty is of two types: Deep anterior
              lamellar Keratoplasty (DALK) and Deep lamellar endothelial
              keratoplasty (DLEK).
            </li>
          </ul>
          <h3 className="text-center">REFRACTIVE AND OTHER LASER PROCEDURES</h3>
          <hr className="mb-5 corneaLine" />
          <p className="my-4">
            Corneal injuries may result from severe blunt trauma or any kind of
            penetrating injury. The aim is to restore and maintain the integrity
            of the globe, avoid further intraocular damage and prevent permanent
            corneal scarring and astigmatism.
          </p>
          <ul>
            <li className="my-3">
              LASIK: An acronym for Laser-assisted In Situ Keratomileusis, is a
              form of refractive laser eye surgery procedure performed by cornea
              specialists as an alternative for people wearing glasses and
              contact lenses.
            </li>
            <li className="my-3">
              PRK: Photo Refractive Keratectomy is an excellent alternative to
              LASIK in selective cases where LASIK cannot be performed due to
              safety considerations as corneal thickness may be inadequate.
            </li>
            <li className="my-3">
              PTK: Photo therapeutic Keratectomy is an excimer laser based
              therapeutic surgery done for numerous conditions ranging from
              corneal dystrophies to recurrent corneal erosions. It not only
              improves vision but also brings relief from recurrent episodes of
              painful red eye and also helps to delay the need for cornea
              transplantation.
            </li>
          </ul>
          <h3 className="text-center">OTHER CORNEAL SURGICAL PROCEDURES</h3>
          <hr className="mb-5 corneaLine" />
          <ul>
            <li className="my-2">Amniotic Membrane Transplantation.</li>
            <li className="my-2">Pterygium surgeries.</li>
            <li className="my-2">Limbal Stem Cell Transplantation.</li>
            <li className="my-2">
              Patch Grafts- Corneal and scleral patch grafts
            </li>
            <li className="my-2">
              Corneal perforation repair with anterior segment reconstruction.
            </li>
            <li className="my-2">Corneal biopsy</li>
            <li className="my-2">Keratoprosthesis</li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
}
