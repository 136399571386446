import React, { useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "../Services/Cataract.css";

export default function Cataract() {
  return (
    <div>
      <Header />
      <div className="container mt-5">
        <div>
          <h1 className="text-center">Cataract</h1>
          <hr className="mb-5 cataractLine" />
          <div>
            <div id="demo" className="carousel slide" data-ride="carousel">
              {/* The slideshow */}
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="cataract2.png"
                    className="img-fluid"
                    alt="CatractSurgery"
                    width={1100}
                    height={500}
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="cataract2.png"
                    className="img-fluid"
                    alt="CatractSurgery"
                    width={1100}
                    height={500}
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="cataract2.png"
                    className="img-fluid"
                    alt="CatractSurgery"
                    width={1100}
                    height={500}
                  />
                </div>
              </div>
              {/* Left and right controls */}
              <a
                className="carousel-control-prev"
                href="#demo"
                data-slide="prev"
              >
                <span className="carousel-control-prev-icon" />
              </a>
              <a
                className="carousel-control-next"
                href="#demo"
                data-slide="next"
              >
                <span className="carousel-control-next-icon" />
              </a>
            </div>
            <p className="my-4">
              When the lens in your eye becomes cloudy, the light reaching the
              retina is blurred and distorted and your vision is affected then
              this clouded lens is called a cataract which must be removed for
              vision to be restored. A clouded lens can be compared to a window
              that is frosted or "fogged" with steam. Cataracts are treated as a
              surgical procedure that has become a fairly common practice in
              India. The two most common types of cataracts are: cortical
              cataract and a posterior sub capsular cataract. Depending on the
              type of cataract, a patient will experience different visual
              problems, but the most common cataract symptoms include:
            </p>
            <ul>
              <li>Sensitivity to light or glare</li>
              <li>Blurring vision</li>
              <li>Double vision in one eye</li>
              <li>Poor night vision</li>
              <li>Needing brighter light to read</li>
              <li>Experiencing fading or yellowing of colors</li>
              <li>
                If the cloudiness is not near the center of the lens, you may
                not be aware that you have a cataract
              </li>
            </ul>
            <p className="my-4">
              Many cataracts take years to develop to the point where vision is
              seriously affected. Most occur as a result of the normal aging
              process. The types of age-related cataracts are usually described
              by their location in the lens. They are: nuclear cataracts,
              cortical cataracts and sub capsular cataracts. Nuclear Cataracts
              occur in the centre of the lens and may induce other eye problems,
              such as Myopia. Cortical Cataracts tend to occur in persons with
              diabetes, beginning from the outer portion of the lens and slowly
              moving inward. Sub Capsular Cataracts develop under the capsule,
              often at the back of the lens. This type of cataract also occurs
              more in patients suffering from diabetes, but is also found in
              persons with high myopia, adults with Retinitis Pigmentosa and in
              patients who take steroids.
            </p>
            <p className="my-4">
              There are other kinds of cataracts not related to the aging
              process. Traumatic Cataracts develop as a result of eye injuries.
              Others can develop from metabolic blood disorders, eye infections
              and inflammations and certain types of medications. Another type,
              called Congenital Cataract, occurs at birth, particularly if the
              mother has had Rubella (German measles) during pregnancy. Research
              continues to look for ways to prevent cataracts. Until then, good
              vision can be restored in 98 percent of all patients who have
              normal, healthy eyes after surgery.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
