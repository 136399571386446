import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import "./Home.css";
import { Link } from "react-router-dom";

export default function Home() {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div>
      <Header show={true} />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-4">
            <img
              src="/dr-rushmika.webp"
              className="img-fluid"
              alt="Dr Rushmika"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="col-sm-4 mt-4 text-center">
            <div>
              <h1 style={{ fontSize: "30px" }}>
                Welcome To Chandigarh Eye Care Hospital
              </h1>
              <hr className="line" />
              <p style={{ fontSize: "16px" }} className="mt-4 font-weight-bold">
                Achievements
              </p>
              <p>Our achivements speak for us.</p>
            </div>
            <div className="mt-5">
              <div className="left">
                <p style={{ fontSize: "16px" }} className=" font-weight-bold">
                  14
                </p>
                <p>Years of Experience</p>
              </div>
              <div>
                <p style={{ fontSize: "16px" }} className=" font-weight-bold">
                  20,000
                </p>
                <p>Happy Patients</p>
              </div>
              <div className="right">
                <p style={{ fontSize: "16px" }} className=" font-weight-bold">
                  3,000
                </p>
                <p>Surgeries</p>
              </div>
              <div>
                <p style={{ fontSize: "16px" }} className=" font-weight-bold">
                  2
                </p>
                <p>Location</p>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="mb-3 text-center">
              <button onClick={onOpenModal} className="mt-5 button">
                <i className="fa fa-calendar-o mr-3"></i> Request Appointment{" "}
                <span className="ml-4">&gt;</span>
              </button>
              {/* modal area */}
              <Modal open={open} onClose={onCloseModal} center>
                <div className="container">
                  <br />
                  <form className="form-group">
                    <div
                      class="AppointmentForm font-weight-bold"
                      style={{ fontSize: "16px" }}
                    >
                      Please fill it to make an appointment
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                        autofocus
                        autoComplete="on"
                      />
                      <span className="help-block" />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="email_addr"
                        placeholder="Email address"
                        required
                      />
                      <span className="help-block" />
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control"
                        id="phone_number"
                        placeholder="Phone number"
                      />
                      <span className="help-block" />
                    </div>
                    <div className="form-group">
                      <input type="date" className="form-control" id="dob" />
                      <span className="help-block" />
                    </div>
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        id="age"
                        placeholder="Age"
                        min={1}
                        max={110}
                        required
                      />
                      <span className="help-block" />
                    </div>
                    <button
                      className="btn btn-lg btn-primary btn-block"
                      type="submit"
                    >
                      Make appointment
                    </button>
                  </form>
                </div>
              </Modal>
              <br />
              <Link to="ourdoctors">
                <button className="mt-5 button">
                  <i className="fa fa-user-md mr-5"></i> Find Doctors{" "}
                  <span className="ml-5">&gt;</span>
                </button>
              </Link>
              <br />
              <a href="https://goo.gl/maps/frpTNha5ZpdZD51z7">
                <button className="mt-5 button">
                  <i className="fa fa-map-marker mr-5"></i> Find Location{" "}
                  <span className="ml-5">&gt;</span>
                </button>
              </a>
              <br />
              <Link to="contact">
                <button className="mt-5 button">
                  <i className="fa fa-phone mr-3"></i> Emergency Contact{" "}
                  <span className="ml-5">&gt;</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="bg p-5">
        <h2 className="text-center heading">
          <span className="text-success">Committed To</span>{" "}
          <span className="text-warning"> Eye Care Excellence</span>
        </h2>
        <div className="container">
          <div className="row">
            <div className="col-sm-3 text-center mt-5">
              <div className="grid">
                <img
                  src="cataract.png"
                  className="mx-auto d-block icon"
                  alt="cataract"
                  style={{ width: "30%", height: "20%" }}
                />
                <div
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                  className="mt-3 mb-4 text-dark"
                >
                  Cataract Surgery
                </div>
                <p>
                  Non-stitch method of cataract removal by ultrasonic
                  fragmentation, with the benefits of greater intraoperative
                  control and faster postoperative recovery.
                </p>
              </div>
            </div>

            <div className="col-sm-3 text-center mt-5">
              <div className="grid">
                <img
                  src="retina.png"
                  alt="Eye"
                  className="mx-auto d-block icon"
                  style={{ width: "30%", height: "20%" }}
                />
                <div
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                  className="mt-3 mb-4 text-dark"
                >
                  Retina Vitreous Service
                </div>
                <p>
                  The Retina is the light sensitive film in the back of the eye.
                  The image is perceived here and transmitted to the brain by
                  the optic nerve.
                </p>
              </div>
            </div>

            <div className="col-sm-3 text-center mt-5">
              <div className="grid">
                <img
                  src="lasik.png"
                  alt="Lasik"
                  className="mx-auto d-block icon"
                  style={{ width: "30%", height: "20%" }}
                />
                <div
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                  className="mt-3 mb-4 text-dark"
                >
                  Lasik & Refractive Surgery
                </div>
                <p>
                  An advanced laser surgery that permanently eliminates your
                  dependence on corrective eyewear or contact lenses with
                  resultant lifestyle advantages.
                </p>
              </div>
            </div>

            <div className="col-sm-3 text-center mt-5">
              <div className="grid">
                <img
                  src="diagnostic.png"
                  alt="Diagnostic Services"
                  className="mx-auto d-block icon"
                  style={{ width: "30%", height: "20%" }}
                />
                <div
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                  className="mt-3 mb-4 text-dark"
                >
                  Diagnostic Services
                </div>
                <p>
                  Advanced eye examination and diagnostic technology work to
                  provide precision readings and in order to enable the best
                  route to optimal vision rehabilitation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-dark p-5">
        <div className="text-center mb-5">
          <div style={{ fontSize: "18px" }} className="text-info">
            Why Choose Us
          </div>
          <div
            className="text-light font-weight-bold"
            style={{ fontSize: "18.72px" }}
          >
            What’s Our Speciality
          </div>
          <hr className="underline" />
        </div>
        <div
          className="text-warning text-center mb-5  font-weight-bold heading2"
          style={{ fontSize: "24px" }}
        >
          Chandigarh Eye Care with a personal touch
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <img src="/images/img1.jpeg" alt="doctors" className="imgAbout" />
            </div>
            <div className="col-sm-6">
              <div className="text-light">
                <div
                  style={{
                    fontSize: "24px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                  className="mb-3"
                >
                  Well Experience Doctors
                </div>
                <p>
                  The best eye doctor in patna is what we all desire being a
                  patient. In Chandigarh Eye Care Patna you will get an
                  opportunity to consult with the best eye specialist in patna
                  who is highly qualified and experienced.
                </p>
                <div
                  style={{
                    fontSize: "24px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                  className="mb-3"
                >
                  High Technology Facilities
                </div>
                <p>
                  We use technologically advanced equipments that guarantee
                  better results.
                </p>
                <div
                  style={{
                    fontSize: "24px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                  className="mb-3"
                >
                  Comfortable Clinics
                </div>
                <p>
                  You can feel how comfortable the clinic is when you experience
                  it. We have taken the best possible resources to provide you
                  world class experiences. We also have best eye doctor in patna
                  who has excellent experience in handling critical case with
                  perfection.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-sm-6 p-5 border text-center">
            <div
              className="my-4 heading2 font-weight-bold"
              style={{ fontSize: "24px" }}
            >
              Dr. Rushmika Singhla
            </div>
            <p className="my-5">
              Dr. Rushmika Singhla, MBBS, DOMS, FRCS – General Surgery, is
              considered the Best Eye Specialist in Patna. She is a skilled
              medical professional in the field of Ophthalmology, offering
              consultation services and dedicated treatments to her patients
              since the last 14 years.
            </p>
            <i className="fa fa-check my-3">
              {" "}
              MBBS – Sri Siddhartha Medical College & Research – 2005
            </i>
            <i className="fa fa-check my-3">
              {" "}
              DOMS – Jawaharlal Nehru Medical College, Belgaum – 2010
            </i>
            <i className="fa fa-check my-3">
              {" "}
              FRCS – General Surgery – Royal College of Physicians and Surgeons
              (RCPS), Glasgow – 2014
            </i>
          </div>

          <div className="col-sm-6">
            <img
              src="/dr-rushmika.webp"
              className="img-fluid"
              alt="Dr Rushmika Singhla"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>

      <div>
        <h4 className="text-dark text-center mt-4" style={{ fontSize: "32px" }}>
          Meet Our Experience Doctors
        </h4>
        <hr className="underline" />
        <div className="container my-5">
          <div className="row">
            <div className="col-sm-6">
              <div className="img-card mb-5">
                <img
                  src="/dr-rushmika.webp"
                  alt="Dr Rushmika Singhla"
                  style={{ width: "40%", height: "40%" }}
                />
                <h3>Dr. Rushmika Singhla</h3>
                <p>Ophthalmologist</p>
                <p>
                  Dr. Rushmika Singhla, MBBS, DOMS, FRCS – General Surgery, is
                  considered the Best Eye Doctor in Patna
                </p>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="img-card2">
                <img
                  src="dr-sharad.webp"
                  alt="Dr. Sharad"
                  style={{ width: "40%", height: "40%" }}
                />
                <h3>Dr. Sharad</h3>
                <p>Anaesthesiologist</p>
                <p>
                  Dr. Sharad is one of the renowned anaesthesiologist in bihar.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-dark text-light text-center">
        <div className="appointment heading3">
          <div style={{ fontSize: "18.72px" }}>Need a Doctor for Check-up?</div>
          <h4 style={{ fontSize: "32px" }}>
            Just Make An Appointment & You're Done!
          </h4>
          <button onClick={onOpenModal} className="btn">
            Make an Appointment
          </button>
        </div>
      </div>

      <section class="tester_new text-center my-5">
        <h5 style={{ fontSize: "32px" }}>What our customer say for us</h5>
        <hr />
        <div class="container">
          <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-ride="carousel"
          >
            <ol class="carousel-indicators" style={{ marginBottom: "-90px" }}>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                class="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
            <div class="carousel-inner" role="listbox">
              <div class="carousel-item active" role="list">
                <div class="row">
                  <div class="col-lg-2"></div>
                  <div class="col-lg-8">
                    <p>
                      "such a fab. experience... my son got an injury in eye and
                      it was so complicated .. but i took him to her and with
                      her treatment he got his recovery within a weak... and the
                      behaviour of other staffs is excellent and in peaceful
                      manner."
                    </p>
                    <hr />
                    <div style={{ fontSize: "17px", fontWeight: "bold" }}>
                      Arun Kumar Singh
                    </div>
                    <p>Patient Father</p>
                  </div>
                  <div class="col-lg-2"></div>
                </div>
              </div>
              <div class="carousel-item" role="list">
                <div class="row">
                  <div class="col-lg-2"></div>
                  <div class="col-lg-8">
                    <p>
                      "Dr Rushmika was empathetic and supportive throughout the
                      treatment and gave us the right guidance throughout, lucky
                      to have such a Great Eye Specialist in Patna"
                    </p>
                    <hr />
                    <div style={{ fontSize: "17px", fontWeight: "bold" }}>
                      Shilpi Singh
                    </div>
                    <p>Patient</p>
                  </div>
                  <div class="col-lg-2"></div>
                </div>
              </div>
              <div class="carousel-item" role="list">
                <div class="row">
                  <div class="col-lg-2"></div>
                  <div class="col-lg-8">
                    <p>
                      "Awesome awesome. She is a very good doctor. I am very
                      satisfied with her treatment and behaviour. Highly
                      recommended"
                    </p>
                    <hr />
                    <div style={{ fontSize: "17px", fontWeight: "bold" }}>
                      Shaunak
                    </div>
                    <p>Patient</p>
                  </div>
                  <div class="col-lg-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
