import React from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import "./OurDoctors.css";

export default function OurDoctors() {
  return (
    <div>
      <Header />
      <div>
        <div>
          <div className="item animated wow fadeIn">
            <img
              src="dr.rushmika.jpg"
              alt="our-doctors"
              className="aboutusImage"
            />
            <div className="overlay title-overlay">
              <div className="text OurDoctorSize">Our Doctors</div>
            </div>
          </div>
        </div>
        <section>
          <div className="text-center OurDoctors">
            <h1>Our Doctors</h1>
            <hr />
            <div className="container">
              <div className="row">
                <div className="col-sm-6 p-5 border text-center bg-light text-dark">
                  <h2 className="my-4 heading2">Dr. Rushmika Singhla</h2>
                  <p className="my-5">
                    Dr. Rushmika Singhla, MBBS, DOMS, FRCS – General Surgery, is
                    considered the Best Eye Specialist in Patna. She is a
                    skilled medical professional in the field of Ophthalmology,
                    offering consultation services and dedicated treatments to
                    her patients since the last 14 years.
                  </p>
                  <i className="fa fa-check my-3">
                    {" "}
                    MBBS – Sri Siddhartha Medical College & Research – 2005
                  </i>
                  <i className="fa fa-check my-3">
                    {" "}
                    DOMS – Jawaharlal Nehru Medical College, Belgaum – 2010
                  </i>
                  <i className="fa fa-check my-3">
                    {" "}
                    FRCS – General Surgery – Royal College of Physicians and
                    Surgeons (RCPS), Glasgow – 2014
                  </i>
                </div>

                <div className="col-sm-6">
                  <img
                    src="/dr-rushmika.webp"
                    className="img-fluid"
                    alt="dr-rushmika"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-dark text-center mt-4">
              Meet Our Experience Doctors
            </h3>
            <hr className="underline" />
            <div className="container my-5">
              <div className="row">
                <div className="col-sm-6">
                  <div className="img-card mb-5">
                    <img src="/dr-rushmika.webp" alt="dr-rushmika" />
                    <h3>Dr. Rushmika Singhla</h3>
                    <p>Ophthalmologist</p>
                    <p>
                      Dr. Rushmika Singhla, MBBS, DOMS, FRCS – General Surgery,
                      is considered the Best Eye Doctor in Patna
                    </p>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="img-card2">
                    <img src="dr-sharad.webp" alt="dr-sharad" />
                    <h3>Dr. Sharad</h3>
                    <p>Anaesthesiologist</p>
                    <p>
                      Dr. Sharad is one of the renowned anaesthesiologist in
                      bihar.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
