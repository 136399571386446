import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "./LowVision.css";

export default function LowVisionTreatment() {
  return (
    <div>
      <Header />
      <div className="container mt-5">
        <h1 className="text-center">Low Vision Aids</h1>
        <hr className="mb-5 LowVisionLine" />
        <div>
          <div id="demo" className="carousel slide" data-ride="carousel">
            {/* The slideshow */}
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src="lowvision.png"
                  className="img-fluid"
                  alt="lowvision"
                  width={1100}
                  height={500}
                />
              </div>
              <div className="carousel-item">
                <img
                  src="lowvision.png"
                  className="img-fluid"
                  alt="lowvision"
                  width={1100}
                  height={500}
                />
              </div>
              <div className="carousel-item">
                <img
                  src="lowvision.png"
                  className="img-fluid"
                  alt="lowvision"
                  width={1100}
                  height={500}
                />
              </div>
            </div>
            {/* Left and right controls */}
            <a className="carousel-control-prev" href="#demo" data-slide="prev">
              <span className="carousel-control-prev-icon" />
            </a>
            <a className="carousel-control-next" href="#demo" data-slide="next">
              <span className="carousel-control-next-icon" />
            </a>
          </div>
        </div>
        <h3 className="my-4">What is Low Vision?</h3>
        <p className="my-4">
          Vision loss that cannot be corrected by ordinary eyeglasses, contact
          lenses, medication or surgery. A person with low vision has extremely
          limited sight that interferes with daily activities. You can still see
          something. And with the help of vision aids, you can stay independent.
        </p>
        <ul>
          <li>To preserve or restore vision.</li>
          <li>Significant field loss.</li>
          <li>Impaired function.</li>
        </ul>
        <h3 className="my-4">What Cause Low Vision?</h3>
        <p className="my-4">
          Low vision can be caused by a number of diseases in the eye. An injury
          to the eye can also result in low vision. And some people are born
          with low vision. The macula may break down, causing loss of central
          vision (macular degeneration). The blood vessels may become weak and
          leak fluid into the eye (diabetic retinopathy). The optic nerve may be
          damaged by glaucoma (increased eye pressure) and be unable to send
          messages to the brain.
        </p>
        <ul>
          <li>Stroke/ brain injury.</li>
          <li>Retinitis pigmentosa.</li>
          <li>Optic Nerve disease.</li>
          <li>Albinism.</li>
          <li>Nystagmus</li>
          <li>Cortical Visual Impairment.</li>
        </ul>
        <h3 className="my-4">What are the Symptoms?</h3>
        <p className="my-4">
          The symptoms of low vision depend on the cause of the vision loss and
          where the problem is in the eye. Symptoms can include:
        </p>
        <ul>
          <li>
            Blank spots, dark spots or wavy lines in the centre of your vision.
          </li>
          <li>Blurred, hazy or cloudy vision or double vision.</li>
          <li>Loss of side (peripheral) vision or colour vision</li>
        </ul>
        <h3 className="my-4">Goals of Low Vision Management?</h3>
        <ul>
          <li className="my-2">
            Increase functionality - Make the most of the remaining vision.
          </li>
          <li className="my-2">Difficulty reading.</li>
          <li className="my-2">Problems writing/ completing paperwork.</li>
          <li className="my-2">
            Inability to recognize distance objects and faces.
          </li>
          <li className="my-2">Loss of peripheral vision (visual field)</li>
          <li className="my-2">Cortical Visual Impairment.</li>
          <li className="my-2">Location of scotoma important!</li>
          <li className="my-2">
            Generalized loss of visual field difficult to compensate for.
          </li>
          <li className="my-2">Reading may require adaptations.</li>
          <li className="my-2">Mobility and independent travel</li>
          <li className="my-2">Glare and contrast.</li>
          <li className="my-2">Need for additional lighting.</li>
          <li className="my-2">Problems with glare.</li>
          <li className="my-2">
            Increased adjustment to changes in illumination.
          </li>
          <li className="my-2">Visual discomfort and fatigue</li>
        </ul>
        <h3 className="my-4">Functional Effects of Low Vision?</h3>
        <ul>
          <li>
            Increase functionality - Make the most of the remaining vision.
          </li>
          <li>Provide link to community resources and support services.</li>
          <li>Education</li>
        </ul>
        <h3 className="my-4">Staying Independent?</h3>
        <p>
          Using vision aids can help you do the things you need and want to do.
          There are many kinds of vision aids. Low-vision centres and some
          doctors sell these aids. They also train you to use your aids. And
          they will talk with you about proper lighting.
        </p>
        <h3 className="my-4">Working with Your Eye Doctor?</h3>
        <p>
          Your eye doctor will check your eyes and help you get the vision aids
          that will work best for you. Or, he or she may refer you to a
          low-vision specialist (an eye doctor who is trained to treat low
          vision). You'll most likely have glasses prescribed for you, too. Many
          vision aids are designed to be used along with glasses. Be sure to
          wear your glasses as directed
        </p>
        <h3 className="my-4">Magnification?</h3>
        <ul>
          <li className="my-2">
            Enlarges image on retina to stimulate more retinal cells.
          </li>
          <li className="my-2">Can be achieved in many ways.</li>
          <li className="my-2">Relative Distance.</li>
          <li className="my-2">Relative Size.</li>
          <li className="my-2">Optical / Angular.</li>
          <li className="my-2">Electronic.</li>
        </ul>
      </div>
      <Footer />
    </div>
  );
}
