import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "./ContactLens.css";

export default function ContactLensService() {
  return (
    <div>
      <Header />
      <div className="container mt-5">
        <h1 className="text-center">Contact Lenses Service</h1>
        <hr className="mb-5 ContactLensLine" />
        <div>
          <div id="demo" className="carousel slide" data-ride="carousel">
            {/* The slideshow */}
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src="contactlens.jpg"
                  className="img-fluid"
                  alt="contactlenses"
                  width={1100}
                  height={500}
                />
              </div>
              <div className="carousel-item">
                <img
                  src="contactlens.jpg"
                  className="img-fluid"
                  alt="contactlenses"
                  width={1100}
                  height={500}
                />
              </div>
              <div className="carousel-item">
                <img
                  src="contactlens.jpg"
                  className="img-fluid"
                  alt="contactlenses"
                  width={1100}
                  height={500}
                />
              </div>
            </div>
            {/* Left and right controls */}
            <a className="carousel-control-prev" href="#demo" data-slide="prev">
              <span className="carousel-control-prev-icon" />
            </a>
            <a className="carousel-control-next" href="#demo" data-slide="next">
              <span className="carousel-control-next-icon" />
            </a>
          </div>
        </div>
        <p className="my-4">
          Contact lenses are thin, curved plastic disks designed to cover the
          cornea; the clear front covering of the eye. Contact lenses provide a
          safe and effective way to correct vision when used with care and
          proper supervision. They can offer a good alternative to eyeglasses
          depending on your eyes and lifestyle.
        </p>
        <p className="my-4">
          Leonardo da Vinci had proposed the idea of applying a corrective lens
          directly to the surface of the eye as early as 1508 and similar
          concepts surfaced from René Descartes in 1636 but it was not until
          1887 that the German physiologist Adolf Eugen Fick constructed the
          first successful contact lens.
        </p>
        <ul>
          <li className="my-3">
            Contact lenses typically provide better vision than spectacles for
            some visual conditions such as severe short-sightedness [myopia],
            long-sightedness [hyperopia], astigmatism, corneal distortion, or
            after cataract removal [aphakia]
          </li>
          <li className="my-3">
            Some people like the wider field of vision provided by contact
            lenses as side vision [peripheral vision] is often blocked, obscured
            or limited by spectacle frames.
          </li>
          <li className="my-3">
            Contact lenses do not fog up with changes in temperature or from
            perspiration and they are unaffected by water or rain.
          </li>
          <li className="my-3">
            Many people choose contact lenses for personal or cosmetic reasons
            because they think glasses make them look less attractive or they
            find the weight of spectacles on their face annoying.
          </li>
          <li className="my-3">
            Sports players and people involved in vigorous activities often find
            contact lenses more convenient than spectacles, especially for water
            sports and contact sports.
          </li>
          <li className="my-3">
            Priests, psychologists and many others also find that contact lenses
            avoid the barrier effect that spectacles may cause, thus leading to
            more direct eye-contact.
          </li>
        </ul>
        <h3>Daily-wear soft lenses:</h3>
        <p className="my-4">
          Daily-wear soft lenses are the most comfortable and your eyes will
          adjust to wearing them in less time than hard contact lenses. These
          soft lenses may be worn during vigorous physical activity and playing
          any sport with less likelihood that the lenses will slip out of place.
          Daily-wear lenses must be removed, cleaned and stored every day.
        </p>
        <h3>Disposable Contact Lenses:</h3>
        <p className="my-4">
          Disposable Contact Lenses are lenses designed to be replaced on a
          regular basis usually daily, bi-weekly or monthly. The more often a
          lens is replaced the less likely the lens will become deposited with
          proteins and oils. Many problems that contact lens wearers experienced
          in the past when lenses were replaced on an annual or two yearly bases
          are seldom seen nowadays. Disposable contact lenses are therefore a
          healthier alternative. Daily disposable contact lenses are a great
          option for people who only want to wear their contact lenses a few
          times a week for example for sport or for going out at night. They
          also remove the need for cleaning, since they are inserted in the
          morning (before the start of your day / play sport / work) and then
          thrown away at the end of the day offering exceptional convenience and
          good value for money. Lenses, which are replaced on a two-weekly or
          monthly basis, are good value if you want to wear lenses every day.
        </p>
        <h3>Leave-in (extended wear) contact lenses:</h3>
        <p className="my-4">
          Leave-in (extended wear) contact lenses are designed to be worn
          continuously for 30 days and nights and then replaced with a new
          contact lens. They offer the ultimate in convenience since they
          usually don't need removing or manual cleaning. Other advantages
          include being able to see without struggling to find your glasses if
          you get up in the night, or not having to worry about removing lenses
          with dirty hands. If you go camping or for any outdoor sport or short
          holiday you won't need to remember to carry bottles of solution around
          with you.
        </p>
        <h3>Toric Contact Lenses:</h3>
        <p className="my-4">
          Toric Contact Lenses are made from the same material as regular
          (spherical) contact lenses. Toric lenses have two powers in them
          created with curvatures at different angles (one for astigmatism the
          other for either myopia or Hyperopia. Have you been told you can't
          wear soft contact lenses because you have astigmatism? You now have an
          option!Here's a mechanism to keep the contact lens relatively stable
          in the eye when you blink or look around. Toric contact lenses cannot
          rotate in your eye and keep your vision crisp.
        </p>
        <h3>Rigid gas Permeable contact lenses:</h3>
        <p className="my-4">
          Rigid gas Permeable contact lenses are usually used for correction of
          moderate levels of astigmatism. They provide excellent clarity of
          vision especially in cases where the astigmatism is irregular (such as
          with keratoconus). Rigid Gas permeable contact lenses offer excellent
          long-term corneal health since they allow almost as much oxygen to
          pass through to the cornea as without a lens on the eye. They require
          a longer period of adaptation than soft lenses. Many of the problems
          associated with reduced oxygen flow through old-style "hard lenses"
          are seldom seen nowadays due to the development in these various
          modern gas permeable lenses.
        </p>
        <h3>Bifocal & Multifocal Contact Lenses:</h3>
        <p className="my-4">
          Bifocal contact lenses are designed to provide good vision to people
          who have a condition called presbyopia The main sign that you're
          developing presbyopia is that you need to hold menus, newspapers and
          other reading material farther from your eyes in order to see it
          clearly. Bifocal contact lenses come in both soft materials and rigid
          gas permeable (GP) materials. Some can be worn on a disposable basis.
          That means you have the convenience of throwing the lenses out at
          specified intervals (even daily, in some cases) and replacing them
          with fresh, new lenses. One lens manufacturer offers a soft multifocal
          contact lens made of silicone hydrogel material. This lens is
          FDA-approved for up to 30 days of continuous wear.
        </p>
        <h3>Color Contact Lenses</h3>
        <p className="my-4">
          Colored contact lenses come in three kinds: visibility tints,
          enhancement tints and opaque color tints. Many of these colored
          contact lenses are available in Plano form, as well as in designs for
          people who have astigmatism, need bifocal contacts or want a
          disposable contact lens. A visibility tint is usually a light blue or
          green tint added to a lens, just to help you see it better during
          insertion and removal, or if you drop it. Since it's a very light
          tint, it does not affect your eye color an enhancement tint is a solid
          but translucent (see-through) tint that is a little darker than a
          visibility tint. An enhancement tint does change your eye color. As
          the name implies, it's meant to enhance the existing colour of your
          eyes. These types of tints are usually best for people who have light
          colored eyes and want to make their eye color more intense. Color
          tints are deeper, opaque tints that can change your eye color
          completely. Usually they are made of patterns of solid colors. If you
          have dark eyes, you'll need this type of color contact lens to change
          your eye color. Color contacts come in a wide variety of colors,
          including hazel, green, blue, violet, amethyst and gray. The companies
          that make colored contact lenses have gone all out to mimic the
          natural look of the colored portion of the eye, called the iris Since
          this area is made up of colorful shapes and lines, some color contacts
          feature a series of tiny colored dots on the lens to make them look
          more natural on the eye. But the center of the lens, the part that
          lies over your pupil is clear so you can see.
        </p>
        <h3>Prosthetic Contact Lenses:</h3>
        <p className="my-4">
          Prosthetic Contact Lenses are used in patients with corneal
          disfigurations, corneal scarring and de-centered pupils.
        </p>
        <h2>Specialty Contact Lenses:</h2>
        <h3>Rose K Lens:</h3>
        <p className="my-4">
          Heralded as "a quantum leap forward in the evolution of lens design
          for the condition" of keratoconus the Rose K lens has become the
          world's most frequently prescribed gas permeable contact lens for
          keratoconus. Unlike traditional contact lenses, the complex geometry
          built into every Rose K contact lens closely mimics the cone like
          shape of the cornea, for every stage of the condition. The result, a
          more comfortable fitting lens for patients and better sight (visual
          acuity). The Rose K lenses complex geometry has only become possible
          since computer controlled contact lens lathes were developed to cut
          sophisticated oxygen permeable polymers to the right shape.
        </p>
        <h3>Scleral Contact Lenses:</h3>
        <p className="my-4">
          A Scleral lens is a large type of contact lens that rests on the
          sclera and creates a tear-filled vault over the cornea. Scleral lenses
          are designed to treat a variety of eye conditions which do not respond
          to other forms of treatment.
        </p>
        <p className="my-4">
          A Scleral lens is a large type of contact lens that rests on the
          sclera and creates a tear-filled vault over the cornea. Scleral lenses
          are designed to treat a variety of eye conditions which do not respond
          to other forms of treatment.
        </p>
        <p className="my-4">
          Modern Scleral lenses are made of a highly oxygen permeable polymer.
          They are also unique in their design in that they fit onto and are
          supported by the sclera, the white portion of the eye. The cause of
          this unique positioning is usually relevant to a specific patient,
          whose cornea may be too sensitive to support the lens directly. In
          comparison to regular contact lenses, Scleral lenses bulge outward
          considerably more. The space between the cornea and the lens is filled
          with artificial tears. The liquid, which is contained in a thin
          elastic reservoir, conforms to the irregularities of the deformed
          cornea, allowing vision to be restored comfortably.
        </p>
        <p className="my-4">
          Scleral lenses may be used to correct any one of a growing number of
          disorders or injuries to the eye, such as Microphthalmia, keratoconus,
          corneal ectasia, Stevens-Johnson syndrome, Sjögren's syndrome,
          aniridia, neurotrophic keratitis (aneasthetic corneas) and pellucid
          degeneration. Injuries to the eye such as surgical complications,
          distorted corneal implants, as well as chemical and burn injuries also
          may be treated by the use of Scleral lenses.
        </p>
        <h3>Some handy tips for safe and comfortable contact lens use:</h3>
        <ul>
          <li className="my-3">Never wear lenses longer than prescribed.</li>
          <li className="my-3">Do not wear daily lenses while sleeping.</li>
          <li className="my-3">
            Do not wear your lenses overnight unless recommended by your contact
            lens practitioner. Overnight wear of contact lenses increases the
          </li>
          <li className="my-3">risk of complications.</li>
          <li className="my-3">
            Always wash, rinse, and dry your hands before handling lenses. Avoid
            pump and moisturizing soap and use lint free cotton towels and
            tissues.
          </li>
          <li className="my-3">
            Do not use saliva to wet your lenses. Do not put lenses in your
            mouth.
          </li>
          <li className="my-3">
            Use only approved contact lens solutions for lubricating or wetting
            your lenses.
          </li>
          <li className="my-3">
            Always use fresh solution in your lens case when disinfecting your
            lenses.
          </li>
          <li className="my-3">
            Ask your contact lens practitioner about wearing lenses during water
            activities and other sports. In some cases alternative lenses may be
            prescribed for sport, other hobbies and specific work related
            activities.
          </li>
          <li className="my-3">
            Schedule and keep follow-up appointments with your contact lens
            practitioner.
          </li>
          <li className="my-3">
            Never use non-sterile home-prepared saline. The use of home-prepared
            saline with contact lenses has been associated with serious
            infections.
          </li>
          <li className="my-3">
            Contact lens storage cases can be a source of bacteria and other
            microbial growth. Lens cases should be cleaned, rinsed and allowed
            to air dry each time the lenses are removed. Replace the lens case
            frequently.
          </li>
          <li className="my-3">
            If your eyes become red, irritated, painful, abnormally light
            sensitive or if your vision worsens while wearing lenses immediately
            remove the lens and consult a contact lens practitioner.
          </li>
          <li className="my-3">
            Do not get lotions, creams, sprays or chemicals in your eyes or on
            your lenses. It is best to insert lenses before applying make-up and
            remove them before removing make-up. Water- based and gel-based
            cosmetics are less likely to damage lenses than oil-based products.
            Do not apply eyeliner on the inside rim of the eyelids.
          </li>
          <li className="my-3">
            You should have eye examinations every 6 x to 12 months or as
            recommended by your contact lens practitioner.Contact lenses wear
            out with time and should be replaced regularly.
          </li>
          <li className="my-3">
            Disposable lenses should be thrown away after the recommended
            wearing period prescribed by your contact lens practitioner. Daily
            disposable lenses should not be re-used.
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
}
