import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Cataract from "./Services/Cataract";
import Cornea from "./Services/Cornea";
import Glaucoma from "./Services/Glaucoma";
import Lasik from "./Services/Lasik";
import LowVisionTreatment from "./Services/LowVisionTreatment";
import RetinaVitreousService from "./Services/RetinaVitreousService";
import SquintService from "./Services/SquintService";
import ContactLensService from "./Services/ContactLensService";
import OurDoctors from "./OurDoctors";

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/contact" component={Contact} />
          <Route path="/cataract" component={Cataract} />
          <Route path="/glaucoma" component={Glaucoma} />
          <Route path="/lasik" component={Lasik} />
          <Route path="/ourdoctors" component={OurDoctors} />
          <Route path="/lowvisiontreatment" component={LowVisionTreatment} />
          <Route
            path="/retinavitreousservice"
            component={RetinaVitreousService}
          />
          <Route path="/contactlensservices" component={ContactLensService} />
          <Route path="/squintservices" component={SquintService} />
          <Route path="/cornea" component={Cornea} />
          <Route path="/about" component={About} />
          <Route exact path="/" component={Home} />
          <Route exact path="*" component={Home} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
