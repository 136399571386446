import React, { useState } from "react";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import "./About.css";

export default function About() {
  return (
    <div>
      <Header />
      <div>
        <div className="item animated wow fadeIn">
          <img src="/images/img2.jpeg" alt="aboutUs" className="aboutusImage" />
          <div className="overlay title-overlay">
            <div className="text">About Us</div>
          </div>
        </div>
      </div>

      <section className="text-center about">
        <h1>About US</h1>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 mb-5">
              <div>
                <img
                  src="/images/img3.jpeg"
                  className="imgAbout"
                  alt="who-we-are"
                />
              </div>
            </div>
            <div
              className="col-sm-6 about-item wow lightSpeedIn mb-3"
              data-wow-offset={200}
            >
              <span className="fa fa-group" />
              <h2>Who we are?</h2>
              <p className="lead">
                Chandigarh Eye Care, started in 2015, Situated in Patna, Bihar,
                was founded by Dr.Rushmika Singhla, Phaco & Refractive Surgeon
                Consultant RK Mission Patna, Ex- Consultant Vasan Eye Care and
                Fellow LV Prasad Eye Institute, Hyderabad. In Nov. 2016 with the
                aim of providing comprehensive eye care services using the best
                in technology and skills.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-6 mb-5">
              <div>
                <img
                  src="/images/img4.jpeg"
                  className="imgAbout"
                  alt="ourGoal"
                />
              </div>
            </div>
            <div
              className="col-sm-6 about-item wow lightSpeedIn mb-3"
              data-wow-offset={200}
            >
              <span className="fa fa-file" />
              <h2>Our Goal</h2>
              <h3>Help Our Customers Needs</h3>
              <p className="lead">
                We have a strong belief that eye treatment can only really be
                successful if harnessed with cutting edge technology and which
                has to be functioned by best eye doctors in Patna. Our promise
                to give the best eye care service in Patna has made us
                continuously procure & upgrade and make the best utilization of
                the latest technologies. And to provide the best innovative eye
                care service, we at Chandigarh Eye Centre Patna, have made a
                significant effort in selecting best eye specialist in Patna.
              </p>
            </div>

            <div className="clearfix visible-md-block visible-sm-block" />
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-6 mb-5">
              <div>
                <img
                  src="/images/img5.jpeg"
                  className="imgAbout"
                  alt="ourMission"
                />
              </div>
            </div>
            <div
              className="col-sm-6 about-item wow lightSpeedIn"
              data-wow-offset={200}
            >
              <span className="fa fa-info" />
              <h2>Our Mission </h2>
              <h3>To Accomodate All Patients</h3>
              <p className="lead">
                At our clinic, we have patients from all over Bihar and
                neighboring states. Our doctors, holding medical degrees from
                prominent institutes, having high qualifications and are counted
                among the best eye specialist in Patna. We specialize in best
                Eye care service in Patna extending from Cornea surgery to
                Lasik, cataract surgery and more.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
